<template>
  <div class="container-main-content create-enterprise">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-4">Tạo doanh nghiệp</h4>
        <div class="content-inner__body-form">
          <ValidationObserver ref="formCreate">
            <form @keyup.enter="handleCreateMerchant">
              <ValidationProvider
                name="Tên doanh nghiệp"
                rules="required|min3"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    type="text"
                    v-model.trim="form.merchantName"
                    class="form-control"
                    id="floatingMerchantName"
                    placeholder="name@example.com"
                  />
                  <label for="floatingMerchantName">Tên doanh nghiệp</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="Địa chỉ đăng nhập doanh nghiệp"
                rules="required|min3"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    type="text"
                    v-model.trim="form.aliasMerchant"
                    class="form-control form-control-with-text"
                    id="floatingPath"
                    placeholder="name@example.com"
                  />
                  <span class="form-input-plus">{{ APP_DOMAIN }}/s/</span>
                  <label for="floatingPath"
                    >Địa chỉ đăng nhập doanh nghiệp</label
                  >
                </div>
                <p
                  v-if="errors[0]"
                  class="text-danger f12 mb-4"
                  style="margin-top: -18px"
                >
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
              <ValidationProvider
                name="Địa chỉ doanh nghiệp"
                rules="required|min3"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    type="text"
                    v-model.trim="form.address"
                    class="form-control"
                    id="floatingAddress"
                    placeholder="name@example.com"
                  />
                  <label for="floatingAddress">Địa chỉ doanh nghiệp</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider>
                <div class="form-floating mb-4">
                  <b-select
                    v-model="selected"
                    class="form-select"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    :options="optionsField"
                  >
                  </b-select>
                  <label for="floatingSelect">Lĩnh vực</label>
                </div>
              </ValidationProvider>
            </form>
          </ValidationObserver>
          <button
            type="button"
            class="btn btn-primary mb-4"
            @click="handleCreateMerchant"
          >
            TIẾP TỤC
          </button>
        </div>
      </div>
      <Footer />
    </div>

    <Loading
      :active="loading.isLoading"
      :can-cancel="true"
      :is-full-page="loading.fullPage"
      color="#3E69F6"
    >
    </Loading>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { APP_DOMAIN } from "../../.env";
import { MerchantService } from "../../services/merchant.service";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      APP_DOMAIN,
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
      loading: {
        isLoading: false,
        fullPage: true,
      },
      token: this.$cookies.get("token"),
      form: {
        userId: this.$cookies.get("userId"),
        merchantName: "",
        aliasMerchant: "",
        address: "",
        field: "",
      },
      selected: "GROCERY",
      optionsField: [],
    };
  },
  created() {
    this.getListBussinessField();
  },
  methods: {
    async getListBussinessField() {
      try {
        const response = await MerchantService.getListBusinessField(this.token);
        if (response.code === "SUCCESSFUL") {
          this.optionsField = response.data.map((item) => {
            return {
              value: item.code,
              text: item.name,
            };
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleCreateMerchant() {
      this.$refs.formCreate.validate().then(async (success) => {
        if (success) {
          try {
            this.loading.isLoading = true;
            this.form.field = this.selected;
            const response = await MerchantService.createMerchant(
              this.form,
              this.token
            );
            if (response.code === "SUCCESS") {
              this.$router.push({
                path: `/select-enterprise${this.currentQueries}`,
              });
            } else if (response.data.code === "ALIAS_MERCHANT_EXISTED") {
              alert("Mã merchant đã tồn tại");
            } else if (response.data.code === "USER_NOT_FOUND") {
              alert("Thông tin user không đúng");
            } else {
              alert(response.data.code);
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.loading.isLoading = false;
          }
        }
      });
    },
  },
};
</script>
